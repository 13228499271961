<template>
    <div class="content">
        <div class="voiceList" v-if="list.length !== 0">
            <div class="box">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="avatar" v-if="item.gender == 1"><img src="../../src/assets/nan.png" alt=""></div>
                    <div class="avatar" v-if="item.gender == 2"><img src="../../src/assets/nv.png" alt=""></div>
                    <div class="avatar" v-if="item.gender == 0"><img src="../../src/assets/xingbieweizhi.png" alt="">
                    </div>

                    <div class="userInfo">
                        <div class="name">
                            <div class="text">{{ item.name }}</div>
                            <div class="edit" @click="edit(item, index)"><img src="../../src/assets/bj1.png" alt="">
                            </div>
                            <div class="playBack_pause" v-if="item.listen_url">
                        <div class="playBack" @click="playMyAudio(item.listen_url, index)" v-if="item.show == false">
                            <img src="../../src/assets/bf1.png" alt="">
                        </div>
                        <div class="playBack" @click="endMyAudio(index)" v-if="item.show == true"><img
                                src="../../src/assets/zt1.png" alt=""></div>
                    </div>
                    <div class="delete" @click="deleted(item, index)"><img src="../../src/assets/sc1.png" alt="">
                    </div>
                        </div>
                        <div class="tag">
                            <div v-for="itemTag in item.tag" :key="itemTag">{{ itemTag }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 100px;" v-if="list.length == 0">
            <noData />
        </div>
        <el-dialog class="dialog" title="编辑信息" :visible.sync="editShow" width="391px">
            <div class="name">
                <div class="left">音频名称：</div>
                <div class="right"><el-input v-model="nameInput" maxlength="15" :placeholder="items.name"></el-input>
                </div>
            </div>
            <div class="sex">
                <div class="title">性别选择:</div>
                <div class="radio">
                    <el-radio-group v-model="radio">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="personalityLabel">
                <div class="title">个性标签</div>
                <div class="tagBox">
                    <div class="item" @click="addTag(item, index)" v-for="(item, index) in tagList" :key="index">
                        <span class="span">{{ item.tag }}</span>
                    </div>
                </div>
            </div>
            <div class="selected">
                <div class="title">已选择标签</div>
                <div class="box">
                    <div style="display: flex;">
                        <div class="item" @click="editTagName(item, index)" v-for="(item, index) in items.tag"
                            :key="index">
                            <div><span class="da">{{ item }}<span class="xiao"><img @click.stop="deleteTag(item, index)"
                                            src="../../src/assets/shanchu.png" alt=""></span></span></div>
                        </div>
                    </div>
                    <div class="tips">
                        <span><img src="../../src/assets/gantanhao.png" alt="">点击已选择标签进行修改</span>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editShow = false">取 消</el-button>
                <!-- <el-button type="primary" @click="editShow = false">确 定</el-button> -->
                <el-button type="primary" @click="verifyItem()">确 定</el-button>

            </span>
        </el-dialog>
        <el-dialog class="dialog" title="修改标签名称" :visible.sync="editNaeShow" width="391px">
            <el-input v-model="editTagNameValue" maxlength="5"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editNaeShow = false">取 消</el-button>
                <el-button type="primary" @click="verifyTagName">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import noData from '../../components/noData.vue'
import { deleteSoundModel, getTagList, modifyingAudioInfor } from '../../api/api'
export default {
    inject: ['getMysoundModel'],
    components: {
        noData
    },
    name: 'publicVoice',
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            audio: new Audio(),
            playIndex: '',//正在播放的音频的Index
            deleteIndex: '',//准备删除的Index
            tagList: [],//公共标签列表
            editShow: false,//修改音频信息弹框
            editNaeShow: false,//修改标签弹框

            selectIndex: '',//选择的某条音频的index
            items: {},//选择的某条音频
            nameInput: '',//重命名音频名字
            radio: '',//选择男女  男-1  女-2
            editTagNameValue: '',//重命名标签
            selectTagIndex: '',//选中需要修改的标签的index
        }
    },
    created: function () {
        // 获取公共标签
        this.getTagList()

    },
    methods: {
        playMyAudio(url, index) {
            // 判断是否是同一个音频，不是的话其他为false
            for (let i = 0; i < this.list.length; i++) {
                if (i !== index) {
                    this.list[i].show = false
                }
            }
            this.list[index].show = true//修改显示
            // console.log(this.list[index])
            this.$forceUpdate();

            this.playIndex = index
            this.audio.src = url
            this.audio.play();//播放
            this.$emit('myIndex', index)
            this.audio.onended = () => {
                // console.log('要结束了')
                this.list[index].show = false
            }
        },
        endMyAudio(index) {
            this.audio.pause();//暂停
            // this.list[index].show = false
            this.$forceUpdate();
            this.$emit('endAudio', index)
        },

        deleted(item, index) {
            // console.log(item.id, index)

            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.deleteIndex = index
                this.deleteSoundMode(item.id)
                if (this.playIndex == index) {
                    this.endMyAudio(index)

                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        deleteSoundMode(id) {
            let data = {
                id: id
            }
            deleteSoundModel(data).then(res => {
                if (res.data.code == 10000) {
                    this.list.splice(this.deleteIndex, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                this.getMysoundModel()

                }
            })
        },
        // 在公共标签列中向选中标签列表添加
        addTag(item, index) {
            if (this.items.tag.length >= 3) {
                this.$message.error('最多选择三个标签，请删除后在添加');
                return
            }
            for (let i = 0; i < this.items.tag.length; i++) {
                if (this.items.tag[i] == item.tag) {
                    this.$message.error('请勿选择同一个标签');
                    return
                }
            }
            this.items.tag.push(item.tag)
        },
        // 打开音频信息
        edit(item, index) {
            this.selectIndex = index
            this.items = JSON.parse(JSON.stringify(this.list[index])); //深拷贝，为不影响原数组
            this.editShow = true
            this.radio = item.gender
            this.nameInput = this.items.name
        },
        // 修改选择的标签的名称
        editTagName(item, index) {
            this.selectTagIndex = index
            this.editNaeShow = true
        },
        // 在修改信息框了删除已选择的标签
        deleteTag(item, index) {
            this.items.tag.splice(index, 1);
        },
        // 确认修改标签名称
        verifyTagName() {
            if (!this.editTagNameValue) return this.$message.error('标签名不能为空');
            this.items.tag[this.selectTagIndex] = this.editTagNameValue
            this.editNaeShow = false
            this.editTagNameValue = ''
        },
        // 确认修改音频信息
        verifyItem() {
            if (this.nameInput == '') {
                this.$message.error('音频名称不能为空');
                return
            }
            if (this.items.tag.length == 0) {
                this.$message.error('请选择标签');
                return
            }
            let data = {
                id: this.items.id,
                name: this.nameInput,
                gender: this.radio,
                tag: this.items.tag
            }
            // console.log(data)
            modifyingAudioInfor(data).then(res => {
                if (res.data.code == 10000) {
                    this.$message.success('修改成功');
                }
                let index = this.selectIndex
                let list = res.data.data
                list.show = false
                this.list[index] = list

                this.editShow = false
            })
        },
        // 获取公共标签
        getTagList() {
            getTagList().then(res => {
                if (res.data.code == 10000) {
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].state = 0
                        this.tagList.push(list[i])
                    }
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.content {
    .voiceList {
        .box {
            display: flex;
            flex-wrap: wrap;

            .item {
                margin-top: 20px;
                width: 310px;
                padding: 10px 13px;
                border-radius: 50px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #fff;
                background: #6982ff19;
                display: flex;
                margin-left: 10px;
                box-shadow: 0 4px 10px 0#6982ff20;

                .avatar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                    }
                }

                .userInfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 10px;
                    width: 288px;
                    overflow: hidden;

                    .name {
                        white-space: nowrap; //强制不换行
                        font-size: 16px;
                        font-weight: 700;
                        text-align: left;
                        color: #3D3D3D;
                        display: flex;
                        // line-height: 24px;
                        .text {
                            width: 200px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .edit {
                            margin-left: 10px;
                            cursor: pointer;
                            img {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                    }
                        }
                    }
                    .tag {
                        display: flex;

                        div:not(:first-child) {
                            margin-left: 5px;
                        }

                        div {
                            white-space: nowrap; //强制不换行
                            color: #3D3D3D;
                            font-size: 12px;
                            padding: 2px 5px;
                            background-color: #EDEEF7;
                            border-radius: 4px;
                        }
                    }
                }

                .playBack_pause {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                    }
                }

                .delete {
                    img {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    /deep/.el-dialog__body {
        padding: 15px 20px;
    }

    .dialog {
        .name {
            display: flex;
            line-height: 30px;

            .left {
                font-size: 20px;
                color: #000;
            }

            .right {
                /deep/.el-input__inner {
                    border: none;
                    border-bottom: 1px solid #D8D8D8;
                    font-size: 18px;
                    color: #767676;
                    border-radius: 0; // 去除圆角
                    height: 25px;
                    padding: 0;
                }
            }
        }

        .sex {
            display: flex;
            margin-right: 20px;
            flex: 1;
            line-height: 53px;

            .title {
                font-size: 20px;
                color: #000;
            }

            .radio {
                margin-left: 20px;
            }
        }

        .personalityLabel {
            margin-top: 10px;

            .title {
                font-size: 20px;
                color: #000;
            }

            .tagBox::-webkit-scrollbar {
                display: none;
            }

            .tagBox {
                display: flex;
                flex-wrap: wrap;
                overflow-y: scroll;
                height: 120px;

                .item {
                    width: calc(100% / 3);
                    margin-top: 10px;
                    text-align: center;
                    font-size: 16px;
                    color: #3D3D3D;
                    cursor: pointer;

                    /*悬停变小手的属性*/
                    .span {
                        background-color: #EDEEF7;
                        padding: 5px;

                    }
                }
            }
        }

        .selected {
            margin-top: 10px;

            .title {
                font-size: 20px;
                color: #000;
            }

            .box {
                margin-top: 5px;
                width: 351px;
                height: 66px;
                border-radius: 5px;
                border: 1px solid #CCCACA;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .item {
                    width: calc(100% / 3);
                    margin-top: 10px;
                    text-align: center;
                    font-size: 16px;
                    color: #3D3D3D;
                    cursor: pointer;

                    /*悬停变小手的属性*/
                    .da {
                        background-color: #59AAFF;
                        padding: 5px;
                        color: #fff;
                    }

                    .xiao {
                        margin-left: 5px;

                        img {
                            width: 9px;
                            height: 9px;
                        }
                    }
                }

                .tips {
                    display: flex;
                    justify-content: right;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>