<template>
    <div class="content">
        <Header></Header>
        <div class="header">
            图片克隆
        </div>
        <div class="cloneBox">
            <div class="cloneBox_left">
                <div class="Boxtitle">形象克隆内容</div>
                <!-- 形象名称 -->
                <div class="cloneName">
                    <div class="title"><span>*</span>形象名称</div>
                    <div><el-input type="text" placeholder="请输入形象名称" v-model="cloneNameInput" maxlength="15"
                            show-word-limit>
                        </el-input></div>
                </div>
                <!-- 底板视频 -->
                <div class="backplaneVideo">
                    <div class="title"><span>*</span>图片克隆</div>

                    <div class="uploadBox">
                        <div class="upload">
                            <el-upload :limit="1" class="upload-demo" drag
                                action="https://jsonplaceholder.typicode.com/posts/" multiple>
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            </el-upload>
                        </div>
                        <div class="referenceText">
                            <div class="r_title">文本内容请参考右侧示例文件 </div>
                            <div class="r_text">格式：png jpg</div>
                            <div class="r_text">分辨率：1920px以内 </div>
                            <div class="r_text">文件大小：10mb以内</div>
                            <div class="r_text">其他：********* </div>
                        </div>
                    </div>
                </div>
                <!-- 抠图 -->
                <div class="matting">
                    <div class="title">抠图</div>
                    <div class="text1">
                        <el-checkbox v-model="mattingChecked"> 自动抠图，去除绿幕背景</el-checkbox>
                       
                    </div>
                    <div class="text2">人像训练完成后，可通过抠图设置对抠图效果进行调整或复原</div>

                </div>

            </div>
            <div class="cloneBox_right">
                <div class="right_title">示例图片</div>
                <div class="right_box">
                    <div class="box">
                        
                        <div class="miImg"><img :src="heha" alt=""></div>
                    </div>
                </div>
                <div class="right_text">
                    <div class="right_text_box">
                        <div class="item" v-for="(item, index) in example" :key="index">
                            <div><img src="../../assets/zhengque.png" alt=""></div>
                            <div>{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- 提交 -->
        <div class="submit_box">

            <div class="submit_cancel">
                <div><el-button type="primary">提 交</el-button></div>
                <div><el-button>取 消</el-button></div>
            </div>
            <div class="agreement">

                <el-checkbox
                    v-model="signinChecked">我确认我拥有上传音频的所有必要权力，我保证不会用克隆音频创作违法内容，并且会严格遵守<span>《****克隆协议》</span></el-checkbox>
            </div>

        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'

export default {
    components: {
        Header
    },
    data() {
        return {
            heha: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/openapi/model/2024/09/28/1839866425181749248.png',
            cloneNameInput: '',//形象名称
            example: [
                { name: '提交3~8分钟的原视频素材' },
                { name: '使用高分辨率相机' },
                { name: '在光线充足、安静的环境中录制' },
                { name: '恰当的手势，并不高过胸部' }

            ],
            mattingChecked:false,//抠图
            signinChecked: false
        }
    }
}
</script>
<style lang="less" scoped>
.content {
    .header {
        padding: 0 40px;
        height: 40px;
        background: #FFFFFF;
        line-height: 40px;
    }

    .cloneBox {
        margin: 20px 25px 20px 25px;
        padding: 0 15px 0 15px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;

        .cloneBox_left {
            border-right: 1px solid #D8D8D8;
            width: 60%;

            .Boxtitle {
                color: #3D3D3D;
                line-height: 50px;
            }

            .cloneName {
                margin-top: 20px;
                width: 280px;

                .title {
                    color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px;

                    span {
                        color: red;
                    }
                }
            }

            .backplaneVideo {
                margin-top: 20px;
                padding-bottom: 20px;

                .title {
                    color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px;

                    span {
                        color: red;
                    }
                }

                .uploadBox {
                    display: flex;

                    .referenceText {
                        margin-left: 20px;

                        .r_text {
                            color: #7F7F7F;
                            font-size: 12px;
                            line-height: 40px;
                        }
                    }
                }
            }
            .matting{
                color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px; 
                
            }
        }

        .cloneBox_right {
            width: 40%;

            .right_title {
                color: #3D3D3D;
                line-height: 50px;
                padding: 0 20px;
            }

            .right_box {
                display: flex;
                justify-content: center;

                .box {
                    margin-top: 30px;
                    width: 460px;
                    height: 240px;
                    border-radius: 20px;
                    position: relative;
                    margin-right: 0px;

                    .back {
                        width: 480px;
                        height: 230px;
                        -webkit-filter: blur(5px);
                        -moz-filter: blur(5px);
                        -o-filter: blur(5px);
                        -ms-filter: blur(5px);
                        filter: blur(10px);
                    }

                    .miImg {
                        position: absolute;
                        top: 0;
                        left: 160px;

                        img {
                            display: block;
                            width: 160px;
                            height: 238px;

                        }
                    }
                }

            }

            .right_text {
                display: flex;
                justify-content: center;

                .right_text_box {
                    margin-top: 40px;

                    .item {
                        display: flex;
                        line-height: 40px;

                        img {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .submit_box {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 80px;
        background: #FFFFFF;
        width: 100%;
        display: flex;

        .submit_cancel {
            display: flex;
            height: 100%;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 30px;
            }
        }

        .agreement {
            margin-left: 100px;
            line-height: 80px;
            color: #3D3D3D;

            span {
                color: #3977F3;
            }

        }
    }
}
</style>