<template>
  <div class="app" id="app">
    <router-view />
  </div>
</template>

<script>
import Home from '../src/views/HomeView.vue'
import { initialize } from '../api/api'
export default {
  components: {
    Home
  },
  data() { 
    return {
    }
  },
  methods: {
    start() {
      // let webMessage = localStorage.getItem('webMessage');
      initialize().then(res => {
        localStorage.setItem('webMessage', JSON.stringify(res.data.data))

        // webLogo
        if (res.data.data.website_logo !== '') {
          this.$bus.$emit('webMessage1', true)
        } else {
          this.$bus.$emit('webMessage1', false)
        }

        // 客服码
        if (res.data.data.website_wechat !== '') {
          this.$bus.$emit('webMessage2', true)
        } else {
          this.$bus.$emit('webMessage2', false)
        } 


        // 微信小程序体验码
        if (res.data.data.wechat_miniprogram !== '') {
          this.$bus.$emit('webMessage3', true)
        } else {
          this.$bus.$emit('webMessage3', false)
        }
      })
    }
  },

  created() {
    this.start()
  }
}
</script>

<style lang="less">
html,
body {
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

#app {
  // display: flex;
  height: 100vh;
  /* 使用视窗高度 */
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.main-content {
  flex-grow: 1;
  overflow-y: auto;
  /* 添加垂直滚动条，以防内容过长 */
  padding: 20px;
}

a {
  text-decoration: none;
  /* 去除默认的下划线 */
  outline: none;
  /* 去除旧版浏览器的点击后的外虚线框 */
  color: #000;
  /* 去除默认的颜色和点击后变化的颜色 */
}
</style>
