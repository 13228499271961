<template>
    <div class="imageModule">
        <div class="item">
            <div>
                <div class="back" v-if="item.cover_url"
                    :style="{ backgroundImage: 'url(' + item.cover_url + ')', backgroundSize: '100%' }"></div>
                <div class="miImg">
                    <div class="item_img">
                        <img class="item_img_ab" :src="item.cover_url" alt="">
                        <div class="Play_back"><img @click="playVideo(item)" src="../../src/assets/bofang2.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="actionField">
                    <div class="generate_download">
                        <div class="generate blue" v-if="item.line_id == 1" @click="craft(item)">V{{ item.line_id }}生成
                        </div>
                        <div class="generate orange" v-if="item.line_id == 2" @click="craft2(item)">V{{ item.line_id }}生成
                        </div>
                        <div class="download" @click="downloadd(item)">下载</div>
                    </div>
                </div>
                <div class="name">
                    <div class="text">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            yes: true,
            time: null,
            fullscreenLoading: false//加载

        }
    },
    methods: {
        // 播放视频
        playVideo(item) {
            this.$emit('videoItem', item)
        },
        craft(item) {
            this.$router.push({
                name: "videoTable",
                query: { type: item.line_id }
            });
        },
        craft2(){
            this.$message({
                    message: '线路已下架',
                    type: 'warning'
                });
            return
        },
    }
}
</script>
<style lang="less" scoped>
.popover {
    .refresh {
        text-align: center;
        cursor: pointer;
        /*悬停变小手的属性*/
        line-height: 30px;
    }

    .delete {
        text-align: center;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        /*悬停变小手的属性*/
        line-height: 30px;
    }
}
</style>
<style lang="less" scoped>
.imageModule {
    .item {
        position: relative;
        width: 300px;

        .back {
            width: 300px;
            height: 180px;
            border-radius: 10px 10px 0 0;
        //    -webkit-filter: blur(2px);
        //    -moz-filter: blur(2px);
        //    -o-filter: blur(2px);
        //    -ms-filter: blur(2px);
        //    filter: blur(2px);
        }

        .miImg {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -50.5px;
            .item_img_ab {
                    margin-top: 1px;
                    display: block;
                    width: 101px;
                    height: 179px;
                }
            .item_img:hover {
                cursor: pointer;
                /*悬停变小手的属性*/
                .Play_back {
                    img {
                        display: block;
                    }
                }
            }

            .item_img {
                position: relative;
                .Play_back {

                    position: absolute;
                    top: 50%;
                    margin-top: -22px;
                    left: 50%;
                    margin-left: -22px;

                    img {
                        display: none;
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }

        .actionField {
            height: 48px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.1);

            .generate_download {
                display: flex;

                .blue {
                    background-color: #3E7AFE;

                }

                .orange {
                    background-color: #FF5722;
                }

                div {
                    margin-top: 10px;
                    height: 18px;
                    line-height: 18px;
                    padding: 5px 10px;
                    color: #fff;
                    margin-right: 10px;
                    border-radius: 50px;
                    font-size: 14px;
                    cursor: pointer;
                    /*悬停变小手的属性*/

                }
            }

            .more {
                margin-top: 9px;
                font-size: 18px;
                height: 15px;
                line-height: 15px;
                border-radius: 5px;
                padding: 5px;
                background-color: #fff;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                /*悬停变小手的属性*/
            }
        }

        .name {
            display: flex;
            justify-content: center;
            line-height: 18px;
            margin-top: 10px;
            cursor: pointer;
            margin-bottom: 10px;

            .text {
                font-size: 18px;
                color: #3D3D3D;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>