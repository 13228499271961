<template>
    <div class="myImage">
        <div class="tab">
            <div style="display: flex">
                <div class="item" @click="clickTab(index)"
                    :style="{ background: (tabIndex == index ? item.back : ''), color: (tabIndex == index ? '#fff' : '#000') }"
                    v-for="(item, index) in tab" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="search">
                <div>
                    <el-input placeholder="请输入形象名称" @change="search" prefix-icon="el-icon-search" v-model="searchValue">
                    </el-input>
                </div>
            </div>
        </div> 
        <div class="list" >
            <div class="listItem" v-if="list.length !== 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <comImageModule :item="item" @videoItem="videoItem" />
                </div>
            </div>
            <div class="noData" v-if="list.length == 0">
                <noData />
            </div>
            <div class="paging" v-if="total > 16">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="page"
                    :page-size="16" :total="total" :page-sizes="[16]" @current-change="handlePageChange" />
            </div>
        </div>

        <div>
            <el-dialog :title="playVideoItem.name" :visible.sync="videoShow" :before-close="videoShowBefore"
                width="400px">
                <playVideo :item="playVideoItem" :videoShow="videoShow" />
            </el-dialog>
        </div>
    </div>
</template>
<script>
import comImageModule from '../../../components/digitalImageModule/comImageModule.vue';
import noData from '../../../components/noData.vue'
import playVideo from '../../../components/playVideo.vue'

import { getComModelList } from '../../../api/api'
export default {
    components: {
        comImageModule,
        noData,
        playVideo
    },
    data() {
        return {
            tab: [
                {
                    name: '免费公益形象',
                    back: 'linear-gradient(90deg, #19B9FB 0%, #92CFE8 100%), linear-gradient(270deg, #757ABF 0%, #8583BE 60%, #EAB0D1 100%), #D8D8D8',
                    type: 1
                },
                
                // {
                //     name: 'V2克隆',
                //     back: 'linear-gradient(90deg, #4183F5 0%, #51B1DE 100%',
                //     type: 2
                // },
                
                {
                    name: '商用精品形象',
                    back: 'linear-gradient(90deg, #50C4B8 0%, #29B8FB 100%, #D8D8D8',
                    type: 3
                },
                // {
                //     name: 'V4克隆',
                //     back: 'linear-gradient(270deg, #3F8CFF 0%, #5376FF 99%), #D8D8D8',
                //     type: 4
                // },
                // {
                //     name: 'V5克隆',
                //     back: 'linear-gradient(270deg, #3F8CFF 0%, #5376FF 99%), #D8D8D8',
                //     type: 5
                // },
            ],
            tabIndex: '0',//选中的tab的Index
            searchValue: '',
            list: [],
            line_id: '1',
            total: 0,
            fullscreenLoading: false,//加载

            videoShow:false,
            playVideoItem:{}

        }
    },
    methods: {
        videoItem(e){
            this.playVideoItem = e
            this.videoShow = true
        },
        videoShowBefore() {
            this.videoShow = false
        },
        clickTab(index) {
            if ( index == 3) {
                this.$message({
                    message: '敬请期待！',
                    type: 'success'
                });
                return
            }
            this.tabIndex = index
            this.line_id = this.tab[index].type
            this.page = 1
            this.getComModelList()
        },
        // 搜索
        search() {
            this.page == 1
            this.getComModelList()
        },
        // 翻页
        handlePageChange(currentPage) {
            this.page = currentPage
            this.getComModelList()
        },
        getComModelList() {// 获取公共形象
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
            let data = {
                page: this.page,
                line_id: this.line_id,
                page_size: 16
            }
            getComModelList(data).then(res => {
                if (res.data.code == 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                }
                loading.close();
            })
        },
    },
    created() {
        this.getComModelList()
    }
}
</script>
<style lang="less" scoped>
.tab {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 10px 50px;


    .item {
        width: 107px;
        height: 39.5px;
        line-height: 39.5px;
        text-align: center;
        padding: 0 10px;
        font-size: 16px;
        cursor: pointer;
        /*悬停变小手的属性*/
        border-radius: 24px;
        background-color: #f0f0f0;
        margin: 10px;
        color: #3D3D3D;
    }

    .search {
        display: flex;
        justify-content: right;
        margin-right: 50px;

        div {
            width: 200px;
        }
    }
}

.list {
    height: 678px;
    margin: 0 20px;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -120px;
        margin-top: -70px;
    }

    .listItem {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .item {
            display: flex;
            justify-content: center;
            width: 320px;

            margin-bottom: 20px;
        }
    }

    .paging {
        position: sticky;
        bottom: 0;
        left: 0;
        height: 50px;

        background-color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;

        .el-pagination {
            margin-top: 10px;
        }
    }
}
</style>